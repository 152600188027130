import { Agent, Work } from "@biblioteksentralen/cordata";
import { isJestTest } from "@libry-content/common";
import { Site } from "@libry-content/types";
import fetch from "cross-fetch";
import { shake, sift, zip } from "radash";
import { useEffect, useState } from "react";
import useSWR from "swr";
import { useCommonData } from "../../components/layout/CommonDataProvider";
import { ExternalAgentDataApiParams } from "../../app/api/[siteDomain]/internal/external-agent-data/route";
import { ExternalAgentData, ExternalAgentDataResponse } from "../externalDataFetchers/fetchExternalAgentData";
import { ByNorafId } from "../externalDataFetchers/schemas";
import { SiteSearchConfig, getSearchConfig } from "../searchApi/searchConfig";
import { searchWorks } from "../searchApi/searchWorks";
import { SearchWorksParams } from "@biblioteksentralen/bmdb-search-client";

export type AgentsExternalData = ByNorafId<ExternalAgentData>;

export type AgentWorks = Record<string, WorkSummary[] | undefined>;

export const useAgentsData = (
  agents: Agent[] = []
): { externalData?: Record<string, ExternalAgentData | undefined>; works: AgentWorks } => {
  const { site } = useCommonData();
  const norafIds = sift(agents.map(({ identifiers }) => identifiers.norafId));
  const externalDataResponse = useSWR(
    JSON.stringify(norafIds),
    () => site && getExternalAgentData(site, { norafIds, skipSnl: true })
  );
  const externalData = externalDataResponse.data;
  const haveExternalDataForAllAgents =
    agents.length === 0 ||
    agents.every(({ identifiers }) => !identifiers.norafId || (externalData && identifiers.norafId in externalData));
  const searchConfig = getSearchConfig(site);

  const [works, setAgentWorks] = useState<AgentWorks>({});
  const [isFetchingWorks, setIsFetchingWorks] = useState(false);
  const haveWorksForAllAgents = agents.length === 0 || agents.every(({ id }) => !id || id in works);

  useEffect(() => {
    if (isFetchingWorks || haveWorksForAllAgents || !haveExternalDataForAllAgents) return;

    setIsFetchingWorks(true);

    Promise.all(
      agents.map(({ id, identifiers }) =>
        getAgentWorks(searchConfig, id, externalData?.[identifiers.norafId ?? ""]?.wikidata?.notableWorks)
      )
    ).then((results) => {
      const works = zip(agents, results)
        .filter(([agent]) => !!agent?.id)
        .reduce((acc: AgentWorks, [agent, agentResults]) => ({ ...acc, [agent.id!]: agentResults }), {});

      setAgentWorks(works);
      setIsFetchingWorks(false);
    });
  }, [agents, externalData, searchConfig, isFetchingWorks, haveWorksForAllAgents, haveExternalDataForAllAgents]);

  return { externalData, works };
};

const getExternalAgentData = async (
  site: Site,
  { norafIds, skipSnl, skipWikidataImage }: ExternalAgentDataApiParams
) => {
  const urlParams = new URLSearchParams(
    shake({
      skipSnl: skipSnl?.toString(),
      skipWikidataImage: skipWikidataImage?.toString(),
      norafIds: norafIds.join(","),
    })
  );

  try {
    const url = `/api/internal/external-agent-data?${urlParams.toString()}`;
    const response = await fetch(url);
    const { data, error } = <ExternalAgentDataResponse>await response.json();

    if (error) {
      console.error(`Failed to fetch external data for agent ${norafIds.join(",")}`, { cause: error });
      return {};
    }
    return data ?? undefined;
  } catch (error) {
    console.error(`Failed to fetch external data for agent ${norafIds.join(",")}`, { cause: error });
    return undefined;
  }
};

const getAgentWorks = async (
  searchConfig: SiteSearchConfig,
  agentId: string,
  notableWorkTitles?: string[]
): Promise<WorkSummary[]> => {
  const { isSearchIntegrationEnabled } = searchConfig;
  if (!isSearchIntegrationEnabled) {
    !isJestTest && console.error("Search integration not enabled");
    return [];
  }

  // If we have notable work titles, we attempt to sort the works using a concatenated query
  const searchQuery = notableWorkTitles?.length ? notableWorkTitles.slice(0, 3).join(" ") : undefined;

  const requestData: SearchWorksParams = {
    size: 3,
    filter: { "bmdb.contributor": [agentId] },
    query: searchQuery,
  };

  const { works } = await searchWorks(searchConfig, requestData);
  return works;
};

export type WorkSummary = Pick<Work, "type" | "id" | "title">;
