import { Box, colors, Text, TextProps, Tooltip, VisuallyHidden } from "@biblioteksentralen/react";
import { AlertCircle } from "react-feather";
import { getPath } from "../../utils/getPath";
import { useTranslation } from "../../utils/hooks/useTranslation";
import InternalLink from "../InternalLink";
import { ResolvedEventSummary } from "@libry-content/common";

type Props = TextProps & {
  event: ResolvedEventSummary;
};

export const EventLocation = ({ event, ...chakraProps }: Props) => {
  const { t, ts } = useTranslation();
  if (event.externalLocation) {
    return (
      <Text display="flex" alignItems="center" gap=".3rem" {...chakraProps}>
        <VisuallyHidden>{t("Avholdes ikke i biblioteket")}</VisuallyHidden>
        <Tooltip label={t("Avholdes ikke i biblioteket")}>
          <Box as="span" tabIndex={0}>
            <AlertCircle aria-hidden color={colors.statusYellow} size="1em" />
          </Box>
        </Tooltip>
        {event.location}
      </Text>
    );
  }

  return (
    <Text {...chakraProps}>
      {event.library && (
        <InternalLink variant={"plain"} href={getPath(event.library)}>
          {ts(event.library?.name)}
        </InternalLink>
      )}
      {event.location && ` - ${event.location}`}
    </Text>
  );
};
