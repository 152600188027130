import {
  Box,
  BoxProps,
  ChakraProps,
  colors,
  Flex,
  Heading,
  HStack,
  Icon,
  LinkBox,
  Stack,
  StackDivider,
  StackProps,
  Text,
  VisuallyHidden,
} from "@biblioteksentralen/react";
import {
  eventDateString,
  eventTimeString,
  isRepeatedEventOccurence,
  ResolvedEventSummary,
} from "@libry-content/common";
import { ReactNode, useId } from "react";
import { Clock } from "react-feather";
import { capitalizeFirstLetter } from "../../utils/css/capitalizeFirstLetter";
import { formatDate } from "../../utils/date";
import { getPath } from "../../utils/getPath";
import { useTranslation } from "../../utils/hooks/useTranslation";
import useRerenderInterval from "../../utils/useRerenderInterval";
import { EventLocation } from "../arrangement/EventLocation";
import { EventStatusTitlePrefix } from "../arrangement/EventStatus";
import { Edit } from "../editInSanity/EditInSanity";
import InternalLinkOverlay from "../InternalLinkOverlay";
import { EventAudienceAndTypeBadges } from "./EventAudienceAndTypeBadges";
import { LocationIcon } from "./LocationIcon";
import { useCurrentEventStatus } from "./useCurrentEventStatus";

type Props = { events: ResolvedEventSummary[] };

export const EventsList = ({ events }: Props) => (
  <Stack as="ol" spacing={0} divider={<StackDivider borderColor={colors.grey15} />}>
    {events.map((event) => (
      <EventPreview event={event} key={`${event._id}-${event.startDate}`} as="li" listStyleType="none" />
    ))}
  </Stack>
);

type EventPreviewProps = { event: ResolvedEventSummary; aboveHeading?: ReactNode; hideBadges?: boolean } & StackProps;

export const EventPreview = ({ event, aboveHeading, hideBadges, ...chakraProps }: EventPreviewProps) => {
  const { ts, t } = useTranslation();
  const headerId = useId();

  return (
    <LinkBox paddingY=".5rem" _hover={{ background: "rgba(0,0,0,0.035)" }} aria-labelledby={headerId} {...chakraProps}>
      <HStack spacing={0}>
        <Dato event={event} minW={{ base: "4rem", sm: "5rem", md: "7rem" }} />
        <Stack spacing=".1rem" padding=".5rem 1rem" marginLeft=".25rem">
          {aboveHeading}
          <Heading as="h3" size="md" id={headerId} _hover={{ textDecoration: "underline" }}>
            <InternalLinkOverlay href={getPath(event)}>
              <EventStatusTitlePrefix event={event} />
              {ts(event.title)}
            </InternalLinkOverlay>
          </Heading>
          <Edit
            doc={{
              _id: isRepeatedEventOccurence(event) ? event.series.id : event._id,
              _type: "event",
            }}
          />
          <Text fontSize="sm">{ts(event.teaser)}</Text>
          <HStack spacing=".75rem">
            <div>
              <HStack>
                <Icon aria-label={t("tidspunkt")} as={Clock} flexShrink={0} />
                <Flex gap="0 .5rem" flexWrap="wrap">
                  <Text fontSize="sm">{event.allDay ? t("Hele dagen") : eventTimeString(event)}</Text>
                  <LiveStatus event={event} />
                </Flex>
              </HStack>
              <HStack>
                <Icon aria-label={t("sted")} as={LocationIcon} flexShrink={0} />
                <EventLocation fontSize="sm" event={event} />
              </HStack>
              {!hideBadges && <EventAudienceAndTypeBadges fontSize="2xs" event={event} />}
            </div>
          </HStack>
        </Stack>
      </HStack>
    </LinkBox>
  );
};

const Dato = ({ event, ...chakraProps }: { event: ResolvedEventSummary } & BoxProps) => {
  const { lang } = useTranslation();

  return (
    <Box>
      <VisuallyHidden>{eventDateString(lang, event)}</VisuallyHidden>
      <Box
        aria-hidden={true}
        lineHeight={1.1}
        textAlign="center"
        padding={{ base: ".5rem", md: ".5rem 1rem" }}
        {...chakraProps}
      >
        <Text fontSize="1em" fontWeight="600" {...capitalizeFirstLetter}>
          {formatDate(lang, "E", event.startDate)}
        </Text>
        <Text fontSize="2em" fontWeight="600">
          {formatDate(lang, "d", event.startDate)}
        </Text>
        <Text fontSize=".85em" textTransform="uppercase" lineHeight={1.5}>
          {formatDate(lang, "MMM", event.startDate)}
        </Text>
      </Box>
    </Box>
  );
};

const LiveStatus = ({ event, ...chakraProps }: { event: ResolvedEventSummary } & ChakraProps) => {
  const liveStatus = useCurrentEventStatus(event);
  useRerenderInterval();

  return (
    <Box color={liveStatus?.color} fontWeight={600} fontSize="sm" {...chakraProps}>
      {liveStatus?.text}
    </Box>
  );
};
