import { List, Publication } from "@libry-content/types";
import { groq } from "next-sanity";
import { resolveCreatedBySummary, ResolvedCreatedBySummary } from "../employees/sanityQuery";

export const resolvePublicationSummary = groq`
  _type,
  image,
  title,
  author,
  publicationYear
`;

export type PublicationSummary = Pick<Publication, "image" | "title" | "_type" | "author" | "publicationYear">;

export const resolveListGroqProjecton = groq`
  ...,
  createdBy -> ${resolveCreatedBySummary}
`;

export type ResolvedList = List & { createdBy?: ResolvedCreatedBySummary };

export const resolveListSummary = groq`
  _id,
  _type,
  _createdAt,
  teaser,
  title,
  targetAudiences,
  description,
  promotion,
  // publications in listSummary are only used for decoration purposes. We therefore filter out publications without coverimages and only pick the three first to save data
  publications[defined(image)] [0...3] {
    ${resolvePublicationSummary}
  },
  createdBy -> ${resolveCreatedBySummary}
`;

export type ResolvedListSummary = Pick<
  List,
  "_type" | "_id" | "_createdAt" | "teaser" | "title" | "targetAudiences" | "description" | "promotion"
> & {
  publications?: PublicationSummary[];
  createdBy?: ResolvedCreatedBySummary;
};
