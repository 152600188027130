import { Box, VisuallyHidden } from "@biblioteksentralen/react";
import { useTranslation } from "../utils/hooks/useTranslation";

type Props = {
  itemsLength: number;
  name: string;
};

export const VisuallyHiddenResults = ({ itemsLength = 0, name }: Props) => {
  const { t } = useTranslation();
  return (
    <Box role="status">
      <VisuallyHidden>{t("Viser {itemsLength} {name}", { itemsLength, name })}</VisuallyHidden>
    </Box>
  );
};
