import { Modify } from "@biblioteksentralen/types";
import { Recommendation } from "@libry-content/types";
import { groq } from "next-sanity";
import { resolveCreatedBySummary, ResolvedCreatedBySummary } from "../employees/sanityQuery";
import { PublicationSummary, resolvePublicationSummary } from "../lists/sanityQuery";

export const resolveRecommendationGroqProjection = groq`
  ...,
  createdBy -> ${resolveCreatedBySummary}
`;

export const resolveRecommendationFromOtherLibraryGroqProjection = groq`
  ...,
  "createdBy": site->{_id, _type, name, "coatOfArms": municipality.coatOfArms}
`;

export type ResolvedRecommendation = Modify<Recommendation, { createdBy?: ResolvedCreatedBySummary }>;

export const resolveRecommendationSummary = groq`
  _id,
  _type,
  _createdAt,
  teaser,
  targetAudiences,
  promotion,
  publication { ${resolvePublicationSummary} },
  createdBy -> ${resolveCreatedBySummary}
`;

export type ResolvedRecommendationSummary = Pick<
  Recommendation,
  "_type" | "_id" | "_createdAt" | "teaser" | "targetAudiences" | "promotion"
> & {
  publication?: PublicationSummary;
  createdBy?: ResolvedCreatedBySummary;
};
