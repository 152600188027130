import { Box, Button, Center, List, SimpleGrid, Spinner, colors } from "@biblioteksentralen/react";
import { VisuallyHiddenResults } from "../../../components/VisuallyHiddenResults";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { ContentSearchEntry } from "../../searchApi/searchContent";
import { RenderContentPreview } from "./RenderContentPreview";

type Props = {
  contentSearchEntries: ContentSearchEntry[];
  endOfResults: boolean;
  isValidating: boolean;
  paginate: () => void;
};

const SearchResultsYourLibrary = ({ contentSearchEntries, endOfResults, isValidating, paginate }: Props) => {
  const { t } = useTranslation();

  if (contentSearchEntries.length === 0) return null;

  return (
    <Box as="section" marginBottom="2rem">
      <VisuallyHiddenResults
        itemsLength={contentSearchEntries.length}
        name={contentSearchEntries.length > 1 ? t("side fra biblioteket") : t("sider fra biblioteket")}
      />
      <SimpleGrid
        as={List}
        marginBottom="2rem"
        width="100%"
        sx={{ "> *:not(:last-child)": { borderBottom: `solid ${colors.grey15} 1px` } }}
        spacing="0"
      >
        {contentSearchEntries.map((contentSearchEntry) => (
          <RenderContentPreview key={contentSearchEntry._id} item={contentSearchEntry} />
        ))}
      </SimpleGrid>
      <Center paddingTop="0rem">
        {!endOfResults && (
          <Button variant="secondary" onClick={paginate} isDisabled={isValidating} textDecoration="none">
            {t("Vis flere")}
            {isValidating && <Spinner size="sm" marginLeft="0.5rem" />}
          </Button>
        )}
      </Center>
    </Box>
  );
};

export default SearchResultsYourLibrary;
