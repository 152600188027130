import { createLocalizedExcerptFromRichText } from "@libry-content/common";
import { LocalizedField } from "@libry-content/localization";
import { StaticPage } from "@libry-content/types";
import { groq } from "next-sanity";

export const resolveStaticPageGroqProjecton = groq`
  ...,
  "excerpt": ${createLocalizedExcerptFromRichText("body", 100)},
`;

export type ResolvedStaticPage = StaticPage & {
  excerpt?: LocalizedField<string>;
};

export const resolveStaticPageSummary = groq`
  _type,
  _id,
  title,
  image,
  promotion,
  "excerpt": ${createLocalizedExcerptFromRichText("body", 100)},
  footerMenuKey,
`;

export type ResolvedStaticPageSummary = Pick<
  StaticPage,
  "_id" | "title" | "_type" | "image" | "promotion" | "footerMenuKey"
> & {
  excerpt?: LocalizedField<string>;
};
