import { useEffect, useState } from "react";

/**
 * Use 5 minutes as the interval for auto updating textual content.
 * In our accesibility statement we're arguing that this is seldom enough
 * not to require an option to hide or pause the automatically updated content,
 * as long as it's relatively sparse and doesn't affect the layout of other content.
 */
const autoUpdateIntervalMinutes = 5;

const useRerenderInterval = (intervalMs = 1000 * 60 * autoUpdateIntervalMinutes, disable = false) => {
  const [, forceRerender] = useState(0);

  useEffect(() => {
    if (disable) return;

    const interval = setInterval(() => forceRerender((counter) => counter + 1), intervalMs);
    return () => clearInterval(interval);
  }, [disable, intervalMs]);
};

export default useRerenderInterval;
