import {
  DigitalLibraryService,
  digitalLibraryServiceTypesRequiringAuthentication,
  DigitalLibraryTemplate,
  DigitalLibraryTemplateService,
} from "@libry-content/common";
import { DigitalLibraryCustomService } from "@libry-content/types";
import { groq } from "next-sanity";

export const digitalLibraryAuthenticatedServicesFilter = groq`_type in ${JSON.stringify(
  digitalLibraryServiceTypesRequiringAuthentication
)}`;

export const resolveDigitalLibraryServiceSummary = groq`
  _id,
  _type,
  category,
  name,
  teaser,
  image,
  promotion,
  ...template->{code, category, name, teaser, image }
`;

// These type definitions are a bit hairy, being the interface between the type necessary to get the right
// layout in the studio and the one formed by the groq query in order to be useful in the frontend.
export type ResolvedDigitalLibraryServiceSummary =
  | (Pick<DigitalLibraryCustomService, "_id" | "_type" | "category" | "name" | "teaser" | "image" | "promotion"> & {
      code: null;
    })
  | (Pick<DigitalLibraryTemplateService, "_id" | "_type" | "promotion"> &
      Pick<DigitalLibraryTemplate, "code" | "category" | "name" | "teaser" | "image">);

// For fields that are in both a service and its template (_type etc), the latter are overridden by the former
export const resolveDigitalLibraryService = groq`
  ...template->,
  ...
`;

export type ResolvedDigitalLibraryService =
  | DigitalLibraryCustomService
  | (Omit<DigitalLibraryTemplate, "_type"> & DigitalLibraryService);
