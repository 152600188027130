import { colors } from "@biblioteksentralen/react";
import { norwegianDateToUTC, UTCDateToNorwegian } from "@libry-content/common";
import differenceInDays from "date-fns/differenceInDays";
import endOfDay from "date-fns/endOfDay";
import isAfter from "date-fns/isAfter";
import isBefore from "date-fns/isBefore";
import startOfDay from "date-fns/startOfDay";
import { formatDate } from "../../utils/date";
import { useTranslation } from "../../utils/hooks/useTranslation";
import { DateHelper } from "@libry-content/common";
import { ResolvedEventSummary } from "@libry-content/common";

export const useCurrentEventStatus = (event: ResolvedEventSummary): { text: string; color: string } | undefined => {
  const { t, lang } = useTranslation();
  const { startDate } = event;
  const now = new Date();

  if (event.eventStatus === "cancelled") return undefined;
  if (!startDate) return undefined;
  if (event.allDay) return undefined; // TODO status for allDay-events

  if (isBefore(new Date(startDate), startOfDay(new Date())))
    return { color: colors.statusRed, text: t("Arrangementet er avsluttet") };

  const antallDagerTil = differenceInDays(new Date(startDate), startOfDay(UTCDateToNorwegian(now)));

  if (antallDagerTil >= 6) return undefined;
  if (antallDagerTil >= 2)
    return { color: colors.statusGreen, text: t("På {weekday}", { weekday: formatDate(lang, "EEEE", startDate) }) };
  if (antallDagerTil >= 1) return { color: colors.statusGreen, text: t("I morgen") };

  if (!event.eventStart) return { color: colors.statusYellow, text: t("I dag") };

  const { date: eventStart, minutesFromNow: minutesUntilEventStarts } = DateHelper.fromTime(event.eventStart);
  const { date: eventEnd } = DateHelper.fromTime(event.eventEnd);

  const eventStarted = isAfter(now, eventStart);
  const eventEnded = isAfter(now, eventEnd) || isAfter(now, norwegianDateToUTC(endOfDay(new Date(startDate))));

  if (eventEnded) return { color: colors.statusRed, text: t("Arrangementet er avsluttet") };
  if (eventStarted) return { color: colors.statusYellow, text: t("Arrangementet pågår") };

  const hoursUntilEventStarts = minutesUntilEventStarts / 60;

  if (hoursUntilEventStarts > 5)
    return {
      color: colors.statusYellow,
      text: t("I dag"),
    };

  if (hoursUntilEventStarts > 1)
    return {
      color: colors.statusYellow,
      // The hours must be rounded up, otherwise an event that starts in e.g. 1.1 hours will show as
      // "Starter om under 1 timer", which is the wrong time and grammar.
      text: t("Starter om under {hours} timer", { hours: Math.ceil(hoursUntilEventStarts).toFixed() }),
    };
  if (minutesUntilEventStarts > 45)
    return {
      color: colors.statusYellow,
      text: t("Starter om under en time"),
    };
  if (minutesUntilEventStarts > 2)
    return {
      color: colors.statusYellow,
      text: t("Starter om under {minutter} minutter", { minutter: Math.ceil((minutesUntilEventStarts + 1) / 5) * 5 }),
    };
  return { color: colors.statusYellow, text: t("Starter nå") };
};
