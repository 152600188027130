import { useTranslation } from "../../../utils/hooks/useTranslation";
import { Box, Button, Center, List, SimpleGrid, Spinner } from "@biblioteksentralen/react";
import { VisuallyHiddenResults } from "../../../components/VisuallyHiddenResults";
import { AgentPreview } from "../agents/AgentPreview";
import { useAgentsData } from "../../hooks/useAgentsData";
import { CordataSearchAgentsResponse } from "@biblioteksentralen/bmdb-search-client";

type Props = {
  agents: CordataSearchAgentsResponse["results"];
  endOfResults: boolean;
  isValidating: boolean;
  paginate: () => void;
};

const SearchResultsAgent = ({ agents, endOfResults, isValidating, paginate }: Props) => {
  const { t } = useTranslation();
  const { externalData: agentsExternalData, works: agentsWorks } = useAgentsData(agents);

  if (agents.length === 0) return null;

  return (
    <Box as="section" marginBottom="2rem">
      <VisuallyHiddenResults itemsLength={agents.length} name={agents.length > 1 ? t("personer") : t("person")} />
      <SimpleGrid as={List} spacing="2rem" marginBottom="2rem" width="100%" columns={{ base: 1, sm: 2, md: 3, lg: 4 }}>
        {agents.map((agent) => (
          <AgentPreview
            key={agent.id}
            agent={agent}
            externalData={agentsExternalData?.[agent.identifiers.norafId ?? ""]}
            works={agentsWorks[agent.id]}
          />
        ))}
      </SimpleGrid>
      <Center paddingTop="0rem">
        {!endOfResults && (
          <Button variant="secondary" onClick={paginate} isDisabled={isValidating} textDecoration="none">
            {t("Vis flere")}
            {isValidating && <Spinner size="sm" marginLeft="0.5rem" />}
          </Button>
        )}
      </Center>
    </Box>
  );
};

export default SearchResultsAgent;
