import { Library } from "@libry-content/types";
import { groq } from "next-sanity";

export const resolveLibraryGroqProjection = groq`
  ...
`;

export type ResolvedLibrary = Library;

export const resolveLibrarySummary = groq`
  _id,
  _type,
  name,
  image,
  slug
`;

export type ResolvedLibrarySummary = Pick<Library, "_id" | "_type" | "name" | "image" | "slug">;
