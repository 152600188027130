import { CordataSearchAgentsResponse } from "@biblioteksentralen/bmdb-search-client";
import { useCommonData } from "../../../components/layout/CommonDataProvider";
import { Translate, useTranslation } from "../../../utils/hooks/useTranslation";
import { getSearchConfig } from "../../searchApi/searchConfig";
import { useEffect, useState } from "react";
import { bmdbFetcher } from "@libry-content/integrations";
import useSWRInfinite from "swr/infinite";
import { sift } from "radash";
import SearchResultsAgent from "./SearchResultsAgent";
import { SearchResult } from "./SearchContext";
import { SearchState } from "./useSearchState";
import { Box, Heading } from "@biblioteksentralen/react";

export const agentSearchCategory = "agent" as const;
export type AgentSearchCategory = typeof agentSearchCategory;
export const getAgentSearchCategoryNameMap = (t: Translate["t"]): Record<AgentSearchCategory, string> => ({
  agent: t("Personer"),
});

export const useAgentSearch = (
  searchState: SearchState,
  setSearchState: (searchState: NonNullable<SearchState>) => void
): SearchResult => {
  const { site } = useCommonData();
  const siteSearchConfig = getSearchConfig(site);
  const [numberOfResultsFetched, setNumberOfResultsFetched] = useState<number>(0);
  const { t } = useTranslation();

  type AgentSearchKey = {
    search: "agent";
    query: string | undefined;
    page: number;
  } | null;

  const getKey = (pageIndex: number, previousPageData: CordataSearchAgentsResponse): AgentSearchKey => {
    if (previousPageData && numberOfResultsFetched >= previousPageData.total) return null;
    return { search: "agent", query: searchState?.s, page: pageIndex + 1 };
  };

  const agentFetcher = (key: AgentSearchKey) => {
    const data = bmdbFetcher({
      operation: "searchAgents",
      params: {
        size: 4,
        query: key?.query,
        page: key?.page,
      },
      siteSearchConfig,
    });

    return data;
  };

  const { data, setSize, isLoading, isValidating } = useSWRInfinite(getKey, agentFetcher, {
    initialSize: searchState?.agent?.pagination ?? 1,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  const agents = data ? sift(data.map((result) => result?.results)).flatMap((agents) => agents) : [];
  const lastResult = data?.[data.length - 1];
  const endOfResults = lastResult ? lastResult.total <= agents.length : true;

  useEffect(() => {
    setSize(searchState?.agent?.pagination ?? 1);
    setNumberOfResultsFetched(agents.length);
  }, [setSize, setNumberOfResultsFetched, searchState?.agent?.pagination, agents.length]);

  const handlePagination = () => {
    const pagination = searchState?.agent?.pagination ? searchState.agent.pagination + 1 : 2;
    setSearchState({ agent: { pagination } });
  };

  const agentSearchComponent = (
    <SearchResultsAgent
      agents={agents}
      endOfResults={endOfResults}
      isValidating={isValidating}
      paginate={handlePagination}
    />
  );
  const agentSearchSummary = agents.length > 0 && (
    <Box>
      <Heading as="h2" fontSize="2xl" margin="0 0 1rem">
        {t("Personer")}
      </Heading>
      {agentSearchComponent}
    </Box>
  );

  return {
    summary: agentSearchSummary,
    categoryResults: agents.length > 0 ? [{ label: agentSearchCategory, children: agentSearchComponent }] : [],
    areSearchResultsLoading: isLoading,
    areCategoriesLoading: isLoading,
  };
};
