import { SVGProps } from "react";

export const LocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 16 21" height="1em" width="1em" {...props}>
    <path
      fill="currentColor"
      d="M8 .5a8 8 0 0 0-8 8C0 13.9 7 20 7.3 20.3l.7.2.7-.2C9 20 16 13.9 16 8.5a8 8 0 0 0-8-8Zm0 17.7c-2.1-2-6-6.3-6-9.7a6 6 0 0 1 6-6 6 6 0 0 1 6 6c0 3.3-3.9 7.7-6 9.7ZM8 4.5a4 4 0 0 0-4 4 4 4 0 0 0 4 4 4 4 0 0 0 4-4 4 4 0 0 0-4-4Zm0 6a2 2 0 0 1-2-2c0-1.1.9-2 2-2a2 2 0 0 1 2 2 2 2 0 0 1-2 2Z"
    />
  </svg>
);
