import useSWRInfinite from "swr/infinite";
import { useCommonData } from "../../../components/layout/CommonDataProvider";
import { Translate, useTranslation } from "../../../utils/hooks/useTranslation";
import { ContentSearchEntry, searchContent } from "../../searchApi/searchContent";
import { useEffect } from "react";
import { sift } from "radash";
import SearchResultsYourLibrary from "./SearchResultsYourLibrary";
import { SearchResult } from "./SearchContext";
import { SearchState } from "./useSearchState";
import { Box, Heading } from "@biblioteksentralen/react";

export const yourLibrarySearchCategory = "yourLibrary" as const;
export type YourLibrarySearchCategory = typeof yourLibrarySearchCategory;
export const getYourLibrarySearchCategoryNameMap = (t: Translate["t"]): Record<YourLibrarySearchCategory, string> => ({
  yourLibrary: "Ditt bibliotek",
});

export const useYourLibrarySearch = (
  searchState: SearchState,
  setSearchState: (searchState: NonNullable<SearchState>) => void
): SearchResult => {
  const { t, lang } = useTranslation();
  const { site } = useCommonData();

  type YourLibrarySearchKey = { search: "yourLibrary"; query: string | undefined; page?: number | undefined } | null;

  const getKey = (
    pageIndex: number,
    previousPageData: { content: ContentSearchEntry[]; endOfResults: boolean } | undefined
  ): YourLibrarySearchKey => {
    if (previousPageData && previousPageData?.endOfResults) return null;
    return { search: "yourLibrary", query: searchState?.s, page: pageIndex };
  };

  const fetcher = (key: YourLibrarySearchKey) => {
    if (!site) return null;
    return searchContent({
      searchQuery: key?.query,
      siteDomain: site.domain,
      languageCode: lang,
      size: 4,
      from: key?.page ? key.page * 4 : 0,
    });
  };

  const { data, setSize, isLoading, isValidating } = useSWRInfinite(getKey, fetcher, {
    initialSize: searchState?.yourLibrary?.pagination ?? 1,
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  const contentSearchEntries =
    (data && sift(data.map((result) => result?.content)).flatMap((content) => content)) ?? [];
  const lastResult = data?.[data.length - 1];
  const endOfResults = lastResult ? lastResult.endOfResults : true;

  useEffect(() => {
    setSize(searchState?.yourLibrary?.pagination ? searchState?.yourLibrary?.pagination : 1);
  }, [setSize, searchState?.yourLibrary?.pagination]);

  const handlePagination = () => {
    const pagination = searchState?.yourLibrary?.pagination ? searchState.yourLibrary.pagination + 1 : 2;
    setSearchState({ yourLibrary: { pagination } });
  };

  const yourLibrarySearchComponent = (
    <SearchResultsYourLibrary
      contentSearchEntries={contentSearchEntries}
      endOfResults={endOfResults}
      isValidating={isValidating}
      paginate={handlePagination}
    />
  );

  const yourLibrarySearchSummary = contentSearchEntries.length > 0 && (
    <Box>
      <Heading as="h2" fontSize="2xl" margin="0 0 1rem">
        {t("Ditt bibliotek")}
      </Heading>
      {yourLibrarySearchComponent}
    </Box>
  );

  return {
    summary: yourLibrarySearchSummary,
    categoryResults:
      contentSearchEntries.length > 0
        ? [{ label: yourLibrarySearchCategory, children: yourLibrarySearchComponent }]
        : [],
    areSearchResultsLoading: isLoading,
    areCategoriesLoading: isLoading,
  };
};
