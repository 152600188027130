import {
  digitalLibraryTemplateServiceTypes,
  eventFilters,
  getNorwegianDateNow,
  getNorwegianTimeNow,
  groqEventStart,
  ResolvedEventSummary,
  resolveEventSummaryGroqProjection,
  resolveRepeatedEvents,
  scopeQueryToSite,
} from "@libry-content/common";
import { FrontendLocale } from "@libry-content/localization";
import { groq } from "next-sanity";
import {
  ResolvedDigitalLibraryServiceSummary,
  resolveDigitalLibraryServiceSummary,
} from "../../components/digitalLibrary/sanityQuery";
import { ResolvedEmployee, resolveEmployeeGroqProjection } from "../../components/employees/sanityQuery";
import { ResolvedLibrary, resolveLibraryGroqProjection } from "../../components/library/sanityQuery";
import { ResolvedListSummary, resolveListSummary } from "../../components/lists/sanityQuery";
import {
  ResolvedRecommendationSummary,
  resolveRecommendationSummary,
} from "../../components/recommendation/sanityQuery";
import { ResolvedServiceSummary, resolveServiceSummary } from "../../components/services/sanityQuery";
import { ResolvedStaticPageSummary, resolveStaticPageSummary } from "../../components/staticPage/sanityQuery";
import { sanityClient } from "../../utils/sanity/client";
import { SearchContentRequestData } from "../types";
import { defaultSizes } from "./constants";

export const searchContent = async (data: SearchContentRequestData) => {
  const { searchQuery, siteDomain, size = defaultSizes.content, languageCode, from = 0 } = data;

  if (!searchQuery || !siteDomain || !languageCode) return { content: [], endOfResults: true };

  const params = {
    today: getNorwegianDateNow(),
    timeNow: getNorwegianTimeNow(),
    siteDomain,
    searchQuery: searchQuery.split(/\s/g).map((word) => `*${word}*`), // To apply some fuzzy search. Splitting query on whitespace and appending and prepending with "*". Etc "Hello world" => ["*Hello*", "*world*"]
    size: size + 1, // size + 1 for å kunne vurdere om vi skal vise "vis flere"-knapp. vi brukte før "total" å vurdere om vi skulle vise "vis flere"-knapp, men det er ingen elegant måte å hente ut "total" uten å doble størreslen på groq-spørringen som fører til store kall og dobbelt arbeid på serveren til sanity
    from,
  };

  const query = scopeQueryToSite(contentQuery(languageCode));

  const response = await sanityClient.fetch<SearchContentResponse>(query, params);

  return {
    content: response.content.slice(0, size), // Må fjerne det siste elementet som vi hentet for å vurdere om vi skulle vise "vis flere"-knapp
    endOfResults: response.content.length <= size,
  };
};

// TODO At denne funksjonen må kalles separat fra searchContent fører til to kall istedenfor ett kall. Er det mulig å slå de sammen i koden? Tror det krever litt refaktorering av strukturen i koden, evt at vi bruker swr el. til fetching som automatisk deduper og cacher kall
export const checkForContentHits = async (data: Omit<SearchContentRequestData, "size" | "from">) =>
  (await searchContent(data)).content.length > 0;

export type ContentSearchEntry =
  | ResolvedEventSummary
  | ResolvedRecommendationSummary
  | ResolvedListSummary
  | ResolvedServiceSummary
  | ResolvedStaticPageSummary
  | ResolvedDigitalLibraryServiceSummary
  | ResolvedLibrary
  | ResolvedEmployee;
// TODO implement search for more document types:
// | ResolvedCustomCampaignBannerData

export type SearchContentResponse = {
  content: ContentSearchEntry[];
};

const contentQuery = (locale: FrontendLocale) => groq`{
  "content": [
      ...${matchingEvents(locale)},
      ...${matchingDigitalLibraryServiceTemplates(locale)},
      ...${matchingLibraries(locale)},
      ...${otherMatchingContent(locale)}
    ]
    | order(_score desc, _createdAt desc)
    [$from...($from + $size)]
    {
      ...select(
        _type in ["event", "resolvedRepeatedEventOccurence"] => ${resolveEventSummaryGroqProjection},
        _type == "recommendation" => {${resolveRecommendationSummary}},
        _type == "list" => {${resolveListSummary}},
        _type == "service" => {${resolveServiceSummary}},
        _type == "staticPage" => {${resolveStaticPageSummary}},
        _type == "digitalLibraryCustomService" => {${resolveDigitalLibraryServiceSummary}},
        _type == "employee" => {${resolveEmployeeGroqProjection}},
        _type == "library" => {${resolveLibraryGroqProjection}},
        {...}     
      )
    }
}`;

// Separate filter for events as we need to handle some advanced cases like repeated events and past events and making events apear in order if they have the same _score
const matchingEvents = (locale: FrontendLocale) => groq`
  *[ _type == "event" && ${eventFilters.eventTodayOrLater} ] |
    score(
      boost(title.${locale} match $searchQuery, 10),
      boost(teaser.${locale} match $searchQuery, 5),
      boost(pt::text(body.${locale}) match $searchQuery, 1),
    )
    [_score > 0]
    ${resolveRepeatedEvents(eventFilters.repeatedEventNotFinished)}
    | order(startDate asc, ${groqEventStart} asc)
`;

/**
 * Separate filter for digitalLibraryServiceTemplates as its not possible to resolve references in boost-function
 * Didn't find a way to make this work with scoring, so we just give a fixed score of 5 if name or teaser matches searchQuery
 */
const matchingDigitalLibraryServiceTemplates = (locale: FrontendLocale) => groq`
  *[
    _type in ${JSON.stringify(digitalLibraryTemplateServiceTypes)}
    && (
      template->name.${locale} match $searchQuery ||
      template->teaser.${locale} match $searchQuery
    )
   ]
    {
      ${resolveDigitalLibraryServiceSummary},
      "_score": 5
    }
`;

// Gir mulighet til å gi treff på bibliotek ved søk etter feks "åpningstider" og "kontaktinfo" selv om det ikke står i tittelen eller teaseren
const customMatchStrings: Record<FrontendLocale, string> = {
  nb: "åpningstider åpner åpent opnar opent opningstider kontaktinfo addressen",
  nn: "åpningstider åpner åpent opnar opent opningstider kontaktinfo addressen",
};

// TODO seems like there is a bug in content lake. The first boost function gives a score of 1 even when we set boost to 50. With groq-js on groq.dev it works as expected
const matchingLibraries = (locale: FrontendLocale) => groq`
  *[_type == "library"] |
    score(
      boost(_type == "library" && "${customMatchStrings[locale]}" match $searchQuery, 50), // _type == 'library' is a hack to make content lake give an appropriate score (without it we get a score of 1 even when we set boost to 50)
      boost(name.${locale} match $searchQuery, 15),
      boost(teaser.${locale} match $searchQuery, 5),
    )
    [_score > 0]
`;

// Generisk filter som kan gi treff på flere typer dokumenter. Siden vi har vært ganske gode på å bruke like navn på feltene på tvers av dokumenttyper var det ganske greit å lage et filter som funket for mange dokumenttyper, og felter som ikke eksisterer på alle dokumenttypene kan likevel brukes her og vil bare gi 0 i score. Kunne delt opp i separate filtere pr innholdstype, men tror jeg synes detteble mer lettlest. Spørringen dekkes også med tester som sjekker at vi får treff på de forskjellige feltene.
const otherMatchingContent = (locale: FrontendLocale) => groq`
  *[_type in ["list", "service", "staticPage", "recommendation", "employee", "digitalLibraryCustomService"]]
    | score(
        boost(title.${locale} match $searchQuery, 10),
        boost(name.${locale} match $searchQuery, 10),
        boost(publication.title match $searchQuery, 10),
        boost(name match $searchQuery, 10),
        boost(position.${locale} match $searchQuery, 5),
        boost(publication.author match $searchQuery, 5),
        boost(teaser.${locale} match $searchQuery, 5),
        boost(description.${locale} match $searchQuery, 3),
        boost(pt::text(description.${locale}) match $searchQuery, 1),
        boost(pt::text(body.${locale}) match $searchQuery, 1),
    )
    [_score > 0]
`;
